import { animate } from 'motion'

const lockDropdownCount = new WeakMap()

const reduceMotion = window.matchMedia('(prefers-reduced-motion)').matches

class DetailsDropdown extends HTMLDetailsElement {
  constructor() {
    super()

    this.classes = {
      bodyClass: 'has-dropdown-menu',
    }

    this.events = {
      afterHide: 'menu:afterHide',
      afterShow: 'menu:afterShow',
    }

    this.summaryElement = this.firstElementChild
    this.contentElement = this.lastElementChild
    this._open = this.hasAttribute('open')
    this.summaryElement.addEventListener(
      'click',
      this.onSummaryClicked.bind(this)
    )

    this.detectClickOutsideListener = this.detectClickOutside.bind(this)
    this.detectEscKeyboardListener = this.detectEscKeyboard.bind(this)
    this.detectFocusOutListener = this.detectFocusOut.bind(this)

    this.hoverTimer = null
    this.detectHoverListener = this.detectHover.bind(this)
    this.addEventListener('mouseenter', this.detectHoverListener.bind(this))
    this.addEventListener('mouseleave', this.detectHoverListener.bind(this))
  }

  set open(value) {
    if (value !== this._open) {
      this._open = value

      if (this.isConnected) {
        this.transition(value)
      } else {
        value ? this.setAttribute('open', '') : this.removeAttribute('open')
      }
    }
  }

  get open() {
    return this._open
  }

  get trigger() {
    return 'hover'
  }

  get level() {
    return this.hasAttribute('level') ? this.getAttribute('level') : 'top'
  }

  onSummaryClicked(event) {
    event.preventDefault()

    if (
      this.trigger === 'hover' &&
      this.summaryElement.hasAttribute('data-link')
    ) {
      window.location.href = this.summaryElement.getAttribute('data-link')
    } else {
      this.open = !this.open
    }
  }

  async transition(value) {
    if (value) {
      lockDropdownCount.set(
        DetailsDropdown,
        lockDropdownCount.get(DetailsDropdown) + 1
      )
      document.body.classList.add(this.classes.bodyClass)

      this.setAttribute('open', '')
      this.summaryElement.setAttribute('open', '')
      setTimeout(() => this.contentElement.setAttribute('open', ''), 100)
      document.addEventListener('click', this.detectClickOutsideListener)
      document.addEventListener('keydown', this.detectEscKeyboardListener)
      document.addEventListener('focusout', this.detectFocusOutListener)
      await this.transitionIn()
      this.shouldReverse()
    } else {
      lockDropdownCount.set(
        DetailsDropdown,
        lockDropdownCount.get(DetailsDropdown) - 1
      )
      document.body.classList.toggle(
        this.classes.bodyClass,
        lockDropdownCount.get(DetailsDropdown) > 0
      )

      this.summaryElement.removeAttribute('open')
      this.contentElement.removeAttribute('open')
      document.removeEventListener('click', this.detectClickOutsideListener)
      document.removeEventListener('keydown', this.detectEscKeyboardListener)
      document.removeEventListener('focusout', this.detectFocusOutListener)
      await this.transitionOut()
      if (!this.open) this.removeAttribute('open')
    }
  }

  async transitionIn() {
    this.classList.add('menu--open')
    animate(
      this.contentElement,
      { opacity: [0, 1], visibility: 'visible' },
      {
        duration: reduceMotion ? 0 : 0.6,
        easing: [0.7, 0, 0.2, 1],
        delay: reduceMotion ? 0 : 0,
      }
    )
    animate(
      this.contentElement,
      { pointerEvents: 'auto' },
      {
        duration: reduceMotion ? 0 : 0.3,
        easing: [0.7, 0, 0.2, 1],
        delay: reduceMotion ? 0 : 0.1,
      }
    )

    const translateY = this.level === 'top' ? '-10%' : '2rem'
    return animate(
      this.contentElement.firstElementChild,
      { transform: [`translateY(${translateY})`, 'translateY(0)'] },
      { duration: reduceMotion ? 0 : 0.3, easing: [0.7, 0, 0.2, 1] }
    ).finished
  }

  async transitionOut() {
    this.classList.remove('menu--open')
    animate(
      this.contentElement,
      { opacity: 0, visibility: 'hidden' },
      { duration: reduceMotion ? 0 : 0.2, easing: [0.7, 0, 0.2, 1] }
    )

    animate(
      this.contentElement,
      { pointerEvents: 'none' },
      { duration: reduceMotion ? 0 : 0.3, easing: [0.7, 0, 0.2, 1] }
    )

    const translateY = this.level === 'top' ? '-10%' : '2rem'
    return animate(
      this.contentElement.firstElementChild,
      { transform: `translateY(${translateY})` },
      { duration: reduceMotion ? 0 : 0.6, easing: [0.7, 0, 0.2, 1] }
    ).finished
  }

  detectClickOutside(event) {
    if (
      !this.contains(event.target) &&
      !(event.target.closest('details') instanceof DetailsDropdown)
    ) {
      this.open = false
    }
  }

  detectEscKeyboard(event) {
    if (event.code === 'Escape') {
      const targetMenu = event.target.closest('details[open]')
      if (targetMenu) {
        targetMenu.open = false
      }
    }
  }

  detectFocusOut(event) {
    if (event.relatedTarget && !this.contains(event.relatedTarget)) {
      this.open = false
    }
  }

  detectHover(event) {
    if (this.trigger !== 'hover') return

    if (event.type === 'mouseenter') {
      this.open = true
    } else {
      this.open = false
    }
  }

  shouldReverse() {
    const maxWidth =
      this.contentElement.offsetLeft + this.contentElement.clientWidth * 2
    if (maxWidth > window.innerWidth) {
      this.contentElement.classList.add('should-reverse')
    }
  }
}
customElements.define('details-dropdown', DetailsDropdown, {
  extends: 'details',
})

export default DetailsDropdown
