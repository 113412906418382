class MemberPopup extends HTMLDivElement {
  constructor() {
    super()
    this.popupDuration = Number(this.getAttribute('data-popup-duration'))
    this.showOnce = this.getAttribute('data-show-once')
  }

  connectedCallback() {
    if (
      this.showOnce === 'true' &&
      !sessionStorage.getItem('memberPopupShown')
    ) {
      sessionStorage.setItem('memberPopupShown', 'true')
      this.handlePopup()
    }

    if (this.showOnce === 'false') {
      sessionStorage.removeItem('memberPopupShown')
      this.handlePopup()
    }
  }

  handlePopup() {
    this.classList.remove('member-popup--hidden')

    const timeout = setTimeout(() => {
      this.classList.add('member-popup--hidden')
      clearTimeout(timeout)
    }, this.popupDuration * 1000)
  }
}

customElements.define('member-popup', MemberPopup, { extends: 'div' })
