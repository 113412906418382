class SwitchToggle extends HTMLLabelElement {
  constructor() {
    super()
    this.input = this.querySelector('input')

    this.input.addEventListener('change', this.handleChange)
  }

  connectedCallback() {
    if (this.input.checked || localStorage.getItem('electrify-mode')) {
      document.body.classList.add('electrify-mode')
      this.input.checked = true
    }
  }

  handleChange(event) {
    if (event.target.checked) {
      document.body.classList.add('electrify-mode')
      this.classList.add('switch-toggle--active')

      localStorage.setItem('electrify-mode', 'true')

      const lightning = document.querySelector('lightning-component')
      if (lightning) {
        lightning.triggerMultipleLightning({
          count: 8,
          lifetime: 40,
        })
      }
    } else {
      document.body.classList.remove('electrify-mode')
      this.classList.remove('switch-toggle--active')

      localStorage.removeItem('electrify-mode')
    }
  }
}

window.customElements.define('switch-toggle', SwitchToggle, {
  extends: 'label',
})

document.addEventListener('DOMContentLoaded', () => {
  if (localStorage.getItem('electrify-mode')) {
    document.body.classList.add('electrify-mode')
  }
})
