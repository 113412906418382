import { fetchConfig } from '@/lib/utils'

/**
 * ProductForm is a custom HTMLElement that handles product form submissions, visual state tracking, and
 * associated error messaging.
 */
class ProductForm extends window.HTMLElement {
  constructor() {
    super()

    this.form = this.querySelector('form')
    this.form.querySelector('[name="id"]').disabled = false
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
    this.cart = document.querySelector('cart-drawer')
    this.submitButton =
      this.querySelector('[type="submit"]') ||
      this.parentElement.querySelector('button[type="submit"]')
    if (document.querySelector('cart-drawer')) {
      this.submitButton.setAttribute('aria-haspopup', 'dialog')
    }
  }

  /**
   * Emit an event indicating the visibility state of the product form.
   * @param {boolean} visible - Whether the form is visible or not.
   */
  emitVisualState(visible = false) {
    const event = new CustomEvent('product-form:visual-state', {
      bubbles: true,
      detail: {
        visible,
        id: this.dataset.productId,
      },
    })
    this.dispatchEvent(event)
  }

  connectedCallback() {
    // Emit a custom event when the form enters/leaves the viewport
    if (this.dataset.emitVisualState === 'true') {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          this.emitVisualState(entry.isIntersecting)
        })
      })

      observer.observe(this)
    }
  }

  /**
   * Handle the form submission, including error checking and product addition to cart.
   * @param {Event} evt - The form submission event.
   */
  onSubmitHandler(evt) {
    evt.preventDefault()
    if (this.submitButton.getAttribute('aria-disabled') === 'true') return

    const variantId = this.form.querySelector('[name="id"]').value

    if (this.submitButton.hasAttribute('data-scroll-to')) {
      const element = document.querySelector(this.submitButton.dataset.scrollTo)
      if (element) {
        const emailInput = element.querySelector('input[type="email"]')
        if (emailInput) {
          emailInput.focus()
        }
        return
      }
    }

    if (!variantId) {
      const variantErrorTemplate = window.variantStrings.variantError
      this.handleErrorMessage(
        variantErrorTemplate.replace(
          '{{ option_name }}',
          this.dataset.baseOptionName
        )
      )
      return
    }

    document.dispatchEvent(new CustomEvent('theme:loading:start'), {
      bubbles: true,
    })

    const lightning = document.querySelector('lightning-component')
    if (lightning && localStorage.getItem('electrify-mode')) {
      setTimeout(() => {
        lightning.triggerMultipleLightning({
          count: 8,
          lifetime: 40,
        })
      }, 100)
    }

    this.handleErrorMessage()

    try {
      evt.submitter.setAttribute('aria-disabled', true)
      evt.submitter.classList.add('loading')
    } catch (e) {
      console.log(e)
    }

    const config = fetchConfig('javascript')
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    delete config.headers['Content-Type']

    const formData = new window.FormData(this.form)
    if (this.cart) {
      formData.append(
        'sections',
        this.cart.getSectionsToRender().map((section) => section.id)
      )
      formData.append('sections_url', window.location.pathname)
      this.cart.setActiveElement(document.activeElement)
    }
    config.body = formData

    fetch(`${window.routes.cart_add_url}`, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.handleErrorMessage(response.description)
          this.submitButton.setAttribute('aria-disabled', true)
          this.error = true
          return
        }

        if (!this.cart) {
          window.location = window.routes.cart_url
          return
        }

        this.error = false
        this.cart.renderContents(response)
        this.handleQuickAdd(evt)
      })
      .catch((e) => {
        console.error('Error processing the product form:', e)
      })
      .finally(() => {
        try {
          evt.submitter.classList.remove('loading')
        } catch (e) {
          console.log(e)
        }
        if (this.cart && this.cart.classList.contains('is-empty')) {
          this.cart.classList.remove('is-empty')
        }

        try {
          if (!this.error) evt.submitter.removeAttribute('aria-disabled')
        } catch (e) {
          console.log(e)
          // assume we are on an old browser
          // redirect to main cart
          window.location.href = window.routes.cart_url
        }
        document.dispatchEvent(new CustomEvent('theme:loading:end'), {
          bubbles: true,
        })
      })
  }

  handleQuickAdd(evt) {
    if (evt) {
      if (evt.target.closest('quick-buy-modal')) {
        const modal = evt.target.closest('quick-buy-modal')
        modal.close()
      }
    }
  }

  /**
   * Display or hide the error message based on the provided message.
   * @param {string|false} errorMessage - The error message to display. If false, the error message is hidden.
   */
  handleErrorMessage(errorMessage = false) {
    this.errorMessage =
      this.errorMessage || this.querySelector('[data-error-message]')
    this.errorMessage.toggleAttribute('hidden', !errorMessage)

    if (errorMessage) {
      this.errorMessage.textContent = errorMessage
    }
  }
}

// Register the custom element 'product-form'
window.customElements.define('product-form', ProductForm)
