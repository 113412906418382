class LightningComponent extends HTMLElement {
  constructor() {
    super()
    // Create a shadow root
    this.attachShadow({ mode: 'open' })
    // Bind methods so they have a consistent 'this'
    this.boundResize = this.resizeCanvas.bind(this)
    this.animate = this.animate.bind(this)

    // Create and append a template
    const template = document.createElement('template')
    template.innerHTML = `
        <style>
          canvas {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: exclusion;
            pointer-events: none;
            z-index: 99999;
          }
        </style>
        <canvas></canvas>
      `
    this.shadowRoot.appendChild(template.content.cloneNode(true))

    // Get canvas and context
    this.canvas = this.shadowRoot.querySelector('canvas')
    this.ctx = this.canvas.getContext('2d')

    // set canvas 0 opacity background
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0)'
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

    // Array to hold active lightning bolts
    this.bolts = []
    this.running = false
    this.lastTime = performance.now()
  }

  connectedCallback() {
    this.resizeCanvas()
    window.addEventListener('resize', this.boundResize)
    this.start()
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.boundResize)
    this.stop()
  }

  resizeCanvas() {
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
  }

  // Generate the lightning bolt points using midpoint displacement.
  generateLightning(x1, y1, x2, y2, displacement) {
    const points = [
      { x: x1, y: y1 },
      { x: x2, y: y2 },
    ]

    const subdivide = (points, disp) => {
      const newPoints = []
      for (let i = 0; i < points.length - 1; i++) {
        const p0 = points[i]
        const p1 = points[i + 1]
        const midX = (p0.x + p1.x) / 2
        const midY = (p0.y + p1.y) / 2
        const dx = p1.x - p0.x
        const dy = p1.y - p0.y
        const dist = Math.hypot(dx, dy)
        if (dist === 0) continue
        const nx = -dy / dist
        const ny = dx / dist
        const offset = (Math.random() - 0.5) * disp * 2
        const newMid = {
          x: midX + nx * offset,
          y: midY + ny * offset,
        }
        newPoints.push(p0, newMid)
      }
      newPoints.push(points[points.length - 1])
      return newPoints
    }

    const iterations = 4
    let currentPoints = points
    for (let i = 0; i < iterations; i++) {
      currentPoints = subdivide(currentPoints, displacement * Math.pow(0.7, i))
    }
    return currentPoints
  }

  // Create a lightning bolt object.
  createLightningBolt(x1, y1, x2, y2, lifetime = 200) {
    const distance = Math.hypot(x2 - x1, y2 - y1)
    const bolt = {
      start: { x: x1, y: y1 },
      end: { x: x2, y: y2 },
      lifetime: lifetime,
      age: 0,
      points: this.generateLightning(x1, y1, x2, y2, distance * 0.3),
      update(delta) {
        this.age += delta
      },
      draw(ctx) {
        const alpha = 1 - this.age / this.lifetime
        ctx.strokeStyle = `rgba(255, 0, 0, ${alpha})`
        ctx.lineWidth = 2
        ctx.shadowColor = `rgba(255, 0, 0, ${alpha})`
        ctx.shadowBlur = 20
        ctx.beginPath()
        ctx.moveTo(this.points[0].x, this.points[0].y)
        for (let i = 1; i < this.points.length; i++) {
          ctx.lineTo(this.points[i].x, this.points[i].y)
        }
        ctx.stroke()
        ctx.shadowBlur = 10
      },
      isDead() {
        return this.age >= this.lifetime
      },
    }
    return bolt
  }

  /**
   * Public method to trigger a lightning bolt.
   * You can pass in an options object to customize the start/end coordinates and lifetime.
   *
   * Example:
   *   lightningComponent.triggerLightning({
   *     startX: 100,
   *     startY: 0,
   *     endX: 300,
   *     endY: window.innerHeight,
   *     lifetime: 300
   *   });
   */
  triggerLightning(options = {}) {
    const startX =
      options.startX !== undefined
        ? options.startX
        : Math.random() * window.innerWidth
    const startY = options.startY !== undefined ? options.startY : 0
    const endX =
      options.endX !== undefined
        ? options.endX
        : Math.random() * window.innerWidth
    const endY = options.endY !== undefined ? options.endY : window.innerHeight
    const lifetime =
      options.lifetime !== undefined
        ? options.lifetime
        : 200 + Math.random() * 100
    const bolt = this.createLightningBolt(startX, startY, endX, endY, lifetime)
    this.bolts.push(bolt)
  }

  triggerMultipleLightning({
    count = 5,
    duration = 2000,
    ...lightningOptions
  } = {}) {
    if (duration <= 0) {
      // Trigger all bolts immediately
      for (let i = 0; i < count; i++) {
        this.triggerLightning(lightningOptions)
      }
    } else {
      // Spread the triggers over the specified duration
      const interval = duration / count
      let boltsTriggered = 0
      const triggerBolt = () => {
        this.triggerLightning(lightningOptions)
        boltsTriggered++
        if (boltsTriggered < count) {
          setTimeout(triggerBolt, interval)
        }
      }
      triggerBolt()
    }
  }

  // The animation loop updates and draws all active bolts.
  animate(time) {
    const delta = time - this.lastTime
    this.lastTime = time

    // Clear canvas with a semi-transparent fill for a trailing effect.
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

    // Update and draw each lightning bolt.
    this.bolts.forEach((bolt) => {
      bolt.update(delta)
      bolt.draw(this.ctx)
    })

    // Remove bolts that have expired.
    this.bolts = this.bolts.filter((bolt) => !bolt.isDead())

    if (this.running) {
      requestAnimationFrame(this.animate)
    }
  }

  start() {
    if (!this.running) {
      this.running = true
      this.lastTime = performance.now()
      requestAnimationFrame(this.animate)
    }
  }

  stop() {
    this.running = false
  }
}

customElements.define('lightning-component', LightningComponent)
